.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: #1A1919;
  overflow-x: hidden;
  transition: 0.5s;
}

.expanded {
  height: 100vh;
}

.overlay-content {
  position: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 3%;
  text-decoration: none;
  color: white;
  display: block;
  transition: 0.3s;
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 6rem;
}

.overlay a:hover, .overlay a:focus {
  color: #E7EB38;
  cursor: pointer;
}

.overlay .closebtn {
  position: absolute;
  top: 0px;
  right: 45px;
  font-size: 8rem;
  font-weight: 700;
  cursor: pointer;
  color: #f1f1f1;
  padding-top: 0;

}


@media only screen and (max-width: 600px) {
  .overlay {
    display: flex;
  }

  .overlay-content {
    display: flex;
  }

  .overlay a {
    font-size: 4.5rem;
  }

  .overlay .closebtn {
    top: 0px;
    left: 6%;
    right: initial;
    font-size: 6rem;
    padding: 0;
  }
}