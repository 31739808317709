.wrapper {
	background-color: #6238EB;
}

.navbar {
	background-color: transparent;
	position: sticky;
	top:0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	z-index: 1;
	backdrop-filter: blur(10px);
}
.nav-header {
	padding-top: 10%;
	display: flex;
	background-color: #6238EB;
	display: flex;
	align-items: flex-start;
	font-size: calc(10px + 2vmin);
	color: white;
	justify-content: space-between;
	height: 100%;
}

.burger-nav {
	padding-top: 4%;
	padding-left: 6%;
	padding-bottom: 2%;
	color: #6238EB;
}

.contact-img {
	padding-top: 3%;
	padding-right: 6%;
	width: 20%;
	padding-bottom: 2%;
	color: #6238EB;
	
}

svg {
	max-width: 100%;
}

svg:hover {
	color: #E7EB38;
	cursor: pointer;
	transition: 0.3s;
}

.title-div {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	padding-top: 6%;
}

.title {
	padding-bottom: 6%;
	padding-right: 6%;
	width: 60%;

}

.title:hover {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	-webkit-translate: -10% 0;
	translate: -10% 0;

}

.arrow {
	color: white;
	
}

.about-link {
	padding-left: 6%;
	padding-right: 6%;
	padding-bottom: 6%;
	bottom: 0px;
	text-decoration: none;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: end;
}

.about-link a {
	height: 50px;
}

.rounded-rect {
	height: 200px;
	width: 200px;
	background-color: #E7EB38;
	border-radius: 0 0 0 50px;
	animation: flip 5s infinite ease-in-out;
	margin-top: 45vh;
	margin-bottom: 20vh;
	margin-left: 4%;
}

.rounded-rect:hover {
	cursor: pointer;
}

.contact-svg {
	color: #6238EB;
	float: right;
}

.scroll {
  position: relative;
  width: 100vw;
  height: 15%;
  min-height: 150px;
  background-color: #6238EB;
  overflow: hidden;
  margin: 0;
  padding-top: 8%;
  margin-bottom: 0%;
}

.m-scroll {
  overflow: hidden;
  height: 100%;
  width: 94%;
  white-space: nowrap;
  /* animation: scrollText 5s infinite ease-in-out; */
  margin: 0;
  font-size: 0;
  display: inline-block;
  padding-bottom: 4%;
  position: relative;
  padding-left: 6%;
}

.flip-card-hero {
	background-color: transparent;
	width: 25vw;
	height: 23vw;
	perspective: 2000px; /* Remove this if you don't want the 3D effect */
  }
.flip-card-hero .flip-card-inner {
	/* transform: rotateY(180deg); */
	animation: flip 10s infinite ease-in-out;
  }

.olemazole {
	min-height: 25vw;
	font-family: Space Grotesk;
	font-weight: 700;
	font-size: 18vw;
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	padding-right: 6%;
	color: white;
	justify-content: space-between;
	align-items: flex-end;

}

.ole {
	color: #E7EB38;
	width: 100%;
	display: block;
	text-align: left;
	/* padding-left: 10%; */
}

.main {
	color: #f1f1f1;
}

@keyframes scrollText {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes flip {
	0% {
		transform: rotateY(0deg);
	}	
	20% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(180deg);
	}
	75% {
		transform: rotateY(180deg);
	}
  }


@media only screen and (max-width: 600px) {
	.navbar {
		align-items: center;
		padding-top: 0;
	}

	.title {
		width: 50%;
		padding-right: 10%;
		padding-bottom: 10%;
	}

	.about-link {
		width: 15%;
		padding-left: 10%;
	}

	.title-div {
		height: 80vh;
	}

	.contact-img {
		width: 40%;
		padding-top: 4%;
		padding-right: 8%;
	}

	.contact-svg {
	}

	.burger-nav {
		width: 15%;
		padding-top: 4%;
		padding-left: 8%;
	}
}