.contact-section {
	background-color: #6238EB;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	min-height: 100vh;
	height: 100%;
	padding-top:1vh;
}

.social-media-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 6%;
	width: 80%;
}

.sm-links {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.sm-links a {
	color: white;
	text-decoration: none;
	font-family: "Space Grotesk";
	font-weight: 300;
	font-size: 1.5rem;
}

.contact-text-wrapper {
	padding: 6%;
	width: 100%;
}

.contact-text-footer {
	float: right;
	width: 80%;
}

a:hover {
	color: #000;
}

@media only screen and (max-width: 600px) {
	.contact-section {
		flex-direction: row;
	}

	.social-media-wrapper {
		flex-direction: column-reverse;
		padding-left: 10%;
		align-content: space-between;
		justify-content: space-between;
		height: 90vh;
		width: 40%;
	}

	.contact-text-footer {
	}

	.contact-text-wrapper {
		width: 30%;
		padding-right: 10%;
	}

	.sm-links {
		align-items: flex-start;
	}
}