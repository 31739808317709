.project-preview {
  min-height: 100%;
  height: calc(100vh - 70px);
  width: 100%;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  background-color: #1A1919;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: flex-end;
  background-color: transparent;
}

#preview-photo {
  transition: 0.5s;
}

.hide-preview {
  width: 29%;
  transition: 0.5s;
  transform: scale(1);
}

@media only screen and (max-width: 600px) {
  .hide-preview {
    width: 57%;
  }
}