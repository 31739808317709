.projects-section {
	display: flex;
	padding-top: 6%;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
	flex-direction: row;

}

.projects-header {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-left: 6%;
	padding-bottom: 6%;
	overflow: auto;
	position: sticky;
	position: -webkit-sticky;
	align-self: flex-start;
	top: 45%;
}

.projects-logo {
	margin: 0;
	z-index: 0;
	width: 70px;
}

.projects-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	padding-right: 6%;
	padding-bottom: 10%;	

}

.project-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	padding-bottom: 10%;
}

.project-title {
	display: flex;
	column-gap: 10%;
	padding: 10% 0;
	width: 300px;
	justify-content: flex-end;
}

.project-title h1 {
	font-size: 12rem;
	font-family: "Space Grotesk";
	font-weight: 700;
	padding: 0;
	margin: 0;
	color: #6238EB;
	line-height: 75%;
}

.project-image-container {
	background-color: grey;
	height: 714px;
	width: 1000px;
	border-bottom-left-radius: 8%;
}


.img-overlay {
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: .5s ease;
	background-color: #E7EB38;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	border-bottom-left-radius: 8%;
}

.img-overlay-text {
	text-align: center;
	width: 100%;
	font-family: "Space Grotesk";
	font-weight: 300;
	font-size: 2rem;
}

.project-image-container:hover .img-overlay {
 	opacity: 0.95;
}

.description-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.description-container p {
	margin: 0;
	padding: 0;
	width: 150px;
	text-align: left;
	font-family: "Space Grotesk";
	font-weight: 300;
	font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
	.project-image-container {
	    height: 77vw;
	    width: 85vw;
	}

	.project-title h1 {
		font-size: 8rem;
	}

	.description-container {
		padding-right: 6%;
		min-width: 45vw;
	}

	.project-title {
		justify-content: space-around;
		width: 100%;
	}

	.project-title h1 {
		font-size: 8rem;
	}

	.description-container p {
		font-size: 1.3rem;
	}

	.projects-logo {
		display: none;
	}
}