.container {
  min-height: 100%;
  height: calc(100vh - 70px);
  width: 100%;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: #1A1919;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: flex-end;
}

.project-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.project-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: "Space Grotesk";
  font-weight: 700;
  padding-top: 4%;
  padding-bottom: 6%;
  padding-left: 6%;
}

.project-nav p {
  cursor: pointer;
  font-size: 4rem;
  margin: 0;
  padding-right: 6%;
  padding-left: 6%;
}

.project-nav p:hover {
  color: #E7EB38
}

.close-button {
  cursor: pointer;
  font-size: 10rem;
  line-height: 75%;
  position: fixed;
  padding-top: 4%;
  background: transparent;
  backdrop-filter: blur(10px);
  z-index: 10;
  font-weight: 400;
  padding-left: 15px;
}

.close-button:hover {
  color: #E7EB38;
}


.project-details {
  display: flex;
  padding-top: 4%;
  padding-right: 6%;
  padding-bottom: 2%;
  min-width: 80%;
  justify-content: flex-end;
  color: white;
}

.description-container {
  min-width: fit-content;
}

.description-container p {
  width: 100%;
  text-align: right;
}

.project-details h1 {
  font-size: 12rem;
  font-family: "Space Grotesk";
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: #fff;
  line-height: 75%;
  padding-right: 3%;
}

.main-img {
  padding: 6%;
  width: 80%;
}

.project-content {
  display: flex;
  padding-top: 6%;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  width: 100%;  
}

.content-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 6%;
  padding-bottom: 6%;
  padding-right: 5%;
  overflow: auto;
  position: sticky;
  position: -webkit-sticky;
  align-self: flex-start;
  top: 30%;
  min-width: fit-content;
}

.web-logo {
  margin: 0;
  z-index: 0;
  width: 70px;
}

.category-description {
  width: 80%;
  font-family: "Space Grotesk";
  font-weight: 300;
  font-size: 1.5rem;
  color: #D3D3D3;
  padding-bottom: 10%;
}

.category-description p {
  margin: 0;
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 6%;
  padding-bottom: 6%; 
  flex-wrap: wrap;
  column-gap: 6%;
  min-width: 70%;
}

.hide {
  width: 0;
}

.show {
  transition: 0.5s;
}

.project-photo {
  /* padding-top: 6%; */
}

.project-link {
  width: 100%;
  padding-top: 5%;
}

.project-link a {
  float: right;
  text-decoration: none;
  color:white;
  font-family: "Space Grotesk";
  font-weight: 300;
  font-size: 1.5rem;
  cursor: pointer;
}

.project-link a:hover {
  color: #E7EB38;
  cursor: pointer;
}

.photo-content {
  width: 100%;
  margin: 0;
  padding-bottom: 2%;
  padding-top: 2%;
  margin-bottom: 6%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  border-radius: 0 0 0 50px;
}

.project-photo.show {
  width: 100%;
}

.img-big-horizontal {
  width: 80%;
  
}

.img-small-horizontal {
  width: 37%;
}

.img-small-vertical {
  width: 22.67%;
}

.photo-highlight {
  background-color: #6238EB;
  width: 90%;
}

.photo-highlight .photo-heading {
  width: 90%;
}

.photo-heading {
  width: 80%;
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 5.5rem;
  color: #fff;
  margin:0;
  padding-top: 2%;
  padding-bottom: 2%;
  line-height: 5rem;
}

.photo-description {
  width: 80%;
  font-family: "Space Grotesk";
  font-weight: 300;
  font-size: 1.5rem;
  color: #fff;
  padding-bottom: 0%;
  padding-top: 4%;
}

.photo-description p {
  margin: 0;
  padding-bottom: 4%;
  white-space: pre-wrap;
}

.list-container {
  margin: 0;
  padding-bottom: 4%;
}

.list-item {
  padding-bottom: 2%;
  display: flex;
  justify-content: space-between;
  gap: 4%;
}

.list-item li {
  max-width: 85%;
}

.list-icon {
  height: 48px;
  width: 48px;
}


.short {
  color: #E7EB38;
  font-size: 2.5rem;
}

@media only screen and (max-width: 600px) {

  .project-header {
    flex-direction: column;
  }


  .img-small-horizontal {
    width: 80%;
  }

  .img-small-vertical {
    width: 80%;
  }

  .web-logo {
    width: 43px;
  }

  .project-details {
    justify-content: space-between;
    width: 100%;
    margin-top: 25%;
  }

  .project-details h1 {
    font-size: 8rem;
    padding-left: 6%;
  }

  .project-nav.top {
    position: fixed;
    padding-top: 0%;
    background: transparent;
    z-index: 10;
    padding-bottom: 0;
    backdrop-filter: blur(10px);
  }

  .close-button {
    font-size: 6rem;
    position: relative;
    padding-top: 0;
    background: transparent;
    color: #f1f1f1;
    backdrop-filter: none;
  }

  .close-button:hover {
    color:#E7EB38;
  }

  .project-nav p {
    font-size: 2.5rem;
  }

  .category-description {
    font-size: 1.3rem;
    padding-bottom: 15%;
  }

  .photo-description {
    font-size: 1rem;
    padding-bottom: 0%;
    padding-top: 5%;
  }

  .photo-heading {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .list-container{
    padding-left: 5%;
  }

  .list-item li {
    max-width: 80%;
  }

  .list-icon {
    height: 36px;
  }

  .avatar {
    width: 80px;
  }

}
