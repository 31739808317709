.about-section {
	min-height: 80vh;
	height: 100%;
	display: flex;
	align-items: flex-end;
	padding-top: 4%;
	padding-bottom: 8%;
}

.text-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.about-text {
	width: 80%;
	font-family: 'Space Grotesk';
	font-size: 1.6vw;
	font-weight: lighter;
	margin: 0;
}

.about-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	padding-right: 6%;
	align-content: space-between;
	height: 35vw;
}

.about-logo-wrapper {
	padding-left:6%;
	width: fit-content;
}

.about-logo {
	transition:1s;
	width: 100%;
}

.about-logo:hover{
	width: 150%;
	opacity: 0;
}

.about {
	margin: 0;
	width: 80%;
	padding-top: 6%;
}

.about-title {
	font-family: 'Space Grotesk';
	font-size: 16rem;
	font-weight: bold;
	padding-right: 6%;
	padding-bottom: 6%;
	margin: 0; 
	margin-block-start: 0;
    margin-block-end: 0;
    line-height:75%;
    color: #6238EB;
}


.p {
	margin: 0;
	margin-block-start: 0;
    margin-block-end: 0
}

.flip-card {
  background-color: transparent;
  width: 37vw;
  height: 35vw;
  perspective: 2000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #E7EB38;
  color: black;
  border-bottom-left-radius: 15%;
}

/* Style the back side */
.flip-card-back {
  background-color: transparent;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-back img {
	width: 100%;
}

.rectangle {
	width: 40%;
}

@media only screen and (max-width: 600px) {
	.about-section {
		flex-direction: column;
		padding-top: 25%;
	}
	.about-text {
		width: 100%;
		padding-left: 10%;
	}

	.about {
		width: 30%;
	}

	.rectangle {
	}

	.about-logo-wrapper {
		width: 88vw;
		height: 84vw;
		padding-right: 0%;
		padding-left: 0;
	}

	.text-wrapper {
		padding-left: 10%;
		padding-right: 10%;
		width: 88%
	}

	.about-wrapper {
		width: 88%;
		height: fit-content;
		padding-right: 0;
	}

	.flip-card {
		min-width: 90%;
		min-height: 90%;
		padding-right: 10%;
	}

	.flip-card-front {
		background-color: transparent;
		display: flex;
		align-items: flex-end;
	}

	.about-text {
		font-size: 1.5rem;
		padding-bottom: 6%;
	}
}