.prj-nav-overlay {
	z-index: 100;
	width: 100vw;
	height: 100vh;
	animation: blurrIn 1s;
	animation-fill-mode: forwards; 
	position: fixed;
}

.overlay-out{
	animation: blurrOut 1s;
}

@keyframes blurrOut {
	from {
		backdrop-filter: blur(150px);
	}
	to {
		backdrop-filter: blur(0px);
	}
}

@keyframes blurrIn {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(150px);
	}
}

.overlay-logo{
	position: fixed;
	animation: moveLogoIn 1s;
	animation-fill-mode: forwards; 
	margin-left: 6%;
}

@keyframes moveLogoIn {
  0% {
    transform: translate(0,0) rotate(0deg);
    margin-left: 6%;
    left: 0;
    top:45%;
  }
  100% {
    transform: translate(100%,0%) translateY(-50%) rotate(90deg);
    left: 5%;
    top:100px;
  }
}

.closeAnimation {
	animation: moveLogoOut 1s;
	animation-fill-mode: forwards; 
}

@keyframes moveLogoOut {
  0% {
    transform: translate(100%,0%) translateY(-50%) rotate(90deg);
    left: 5%;
    top:100px;
  }
  100% {
    transform: translate(0,0) rotate(0deg);
    margin-left: 6%;
    left: 0;
    top:45%;
  }
}

.categories-wrapper {
	position: fixed;
	top:150px;
	padding-left: 6%;
	width: 88%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.category{
	display: flex;
	flex-direction: column;
	width: 33%;
}

.category-span{
	padding-left: 40%;
	animation: opacityIn 2s;
	animation-fill-mode: forwards; 
	color: #6238EB;
	height: 100px;
	font-family: "Space Grotesk";
    font-weight: 700;
}

.category-out{
	animation: opacityOut 1s;
	animation-fill-mode: forwards; 
}

.category-items{
	display: flex;
	flex-direction: column;
	padding-left: 42%;
}

.category-items div{
	animation: listSlideIn 1s;
	color:transparent;
	animation-fill-mode: forwards;
	opacity: 0; 
}

.category-items-out {
	animation: listSlideOut 1s;
	animation-fill-mode: forwards; 
}

.project-nav-item {
	display: flex;
	align-items: baseline;
	gap: 4%;
	font-weight: bold;
}

.project-nav-item h2{
	font-family: "Space Grotesk";
    font-weight: 700;
}

.project-nav-item:hover h2{
	cursor: pointer;
}

.project-nav-item h2 {
	color: #6238EB;
}

.project-nav-item:hover p {
	cursor: pointer;
	color: #E7EB38;
}

@keyframes listSlideIn {
	from {
		transform: translateX(-100%);
		opacity: 0;	
	}
	to {
		transform: translateX(0%);	
		color:black;
		opacity: 1;
	}
}

@keyframes listSlideOut {
	from {
		transform: translateX(0%);
		opacity: 1;
	}
	to {
		transform: translateX(100%);
		opacity: 0;	
	}
}

@keyframes opacityIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes opacityOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
