.divider-section {
	display: flex;	
	background-color: #1A1919;
	min-height: 100vh;
	height: 100%;
}

.divider-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
	width: 100%;
}

.divider-text {
	padding-right: 6%;
	padding-bottom: 6%;
	margin: 0;
	width: 50%;
}

@media only screen and (max-width: 600px) {
	.divider-text {
		width: 30%;
		padding-bottom: 10%;
		padding-right: 10%;
	}
}